"use strict";

function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
jQuery(document).ready(function ($) {
  var _$$slick, _$$slick2, _$$slick3;
  $('.herobanner__title').addClass('show');
  if ($('.herobanner__animation').length > 0) {
    if ((typeof bodymovin === "undefined" ? "undefined" : _typeof(bodymovin)) !== undefined && typeof bodymovin !== 'undefined') {
      var file_path = $('.herobanner__animation').data('animation-json');
      var hero_animation = bodymovin.loadAnimation({
        container: document.getElementById('herobanner__animation'),
        path: file_path,
        renderer: 'svg',
        loop: false,
        autoplay: false
      });
      $('.herobanner__animation').addClass('active');
      hero_animation.playSegments([0, 450], true);
    }
  }
  function checkPageTop() {
    if ($(window).scrollTop() === 0) {
      $(".site-header").removeClass("scroll");
    } else {
      $(".site-header").addClass("scroll");
    }
  }
  checkPageTop();
  $(window).scroll(function () {
    checkPageTop();
  });
  var heroAnimations = jQuery('.module__animation');
  heroAnimations.each(function () {
    if ($(this).length > 0) {
      if ((typeof bodymovin === "undefined" ? "undefined" : _typeof(bodymovin)) !== undefined && typeof bodymovin !== 'undefined') {
        var file_path = $(this).data('animation-json');
        var hero_home_animation = bodymovin.loadAnimation({
          container: $(this).get(0),
          // node
          path: file_path,
          renderer: 'svg',
          loop: false,
          autoplay: false
        });
        $(this).addClass('active');
        hero_home_animation.playSegments([0, 450], true);
      }
    }
  });
  $('#videoModal').on('shown.bs.modal', function () {
    $('#videoModal').trigger('focus');
  });

  // Add click event listener to open modal button
  $('.button--video').on('click', function () {
    $('#videoModal').modal('show');
    console.log('test modal');
    // Get the link from the data attribute
    var link = $(this).data('video-url');

    // Set the iframe source
    $('#iframeContainer').html(link);
  });
  $('#videoModal').on('hidden.bs.modal', function () {
    $('#iframeContainer').html("");
  });

  // //Solution animation responsive fix
  // function resizeLine() {
  // 	if ($('.solution-animation .grid-container-small').length > 0 && window.innerWidth < 1180) {
  // 		$('.solution-animation .grid-container-small').each(function () {
  // 			var $topHeight = (($($(this).find('.col1>.step')[0]).outerHeight()) / 2);
  // 			var $bottomHeight = (($($(this).find('.col1')[$(this).find('.col1').length - 1]).outerHeight()) / 2);
  // 			$(this).find('.col2').css({ "margin-top": $topHeight, "margin-bottom": $bottomHeight, "padding-bottom": "0" });
  // 			var $tickHeight = $($(this).find('.tick')[0]).outerHeight() / 2;
  // 			var $tickOffsetTop = $topHeight - $tickHeight + 5;
  // 			var $tickOffsetBottom = $bottomHeight - $tickHeight + 5;
  // 			$($(this).find('.tick')[0]).css({ "top": $tickOffsetTop, "bottom": "unset", "transform": "none" });
  // 			$($(this).find('.tick')[$(this).find('.tick').length - 1]).css({ "bottom": $tickOffsetBottom, "top": "unset", "transform": "none" });
  // 		})

  // 	}
  // }
  // if ($('.solution-animation .grid-container-small').length > 0) {
  // 	resizeLine();
  // 	$(window).on("resize", function () {
  // 		resizeLine();
  // 	});
  // }

  $(".reviews__grid").slick((_$$slick = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 300,
    autoplay: true,
    autoplaySpeed: 6000
  }, _defineProperty(_$$slick, "arrows", false), _defineProperty(_$$slick, "centerMode", true), _defineProperty(_$$slick, "slidesToShow", 1), _defineProperty(_$$slick, "fade", true), _defineProperty(_$$slick, "cssEase", "linear"), _defineProperty(_$$slick, "lazyLoad", "ondemand"), _defineProperty(_$$slick, "mobileFirst", true), _defineProperty(_$$slick, "responsive", [{
    breakpoint: 768,
    settings: 'unslick'
  }]), _$$slick));
  $(".herobanner__logo-wrap").slick((_$$slick2 = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 3000,
    autoplay: true
  }, _defineProperty(_$$slick2, "arrows", false), _defineProperty(_$$slick2, "centerMode", true), _defineProperty(_$$slick2, "slidesToShow", 2), _defineProperty(_$$slick2, "autoplaySpeed", 0), _defineProperty(_$$slick2, "cssEase", "linear"), _defineProperty(_$$slick2, "lazyLoad", "ondemand"), _defineProperty(_$$slick2, "mobileFirst", true), _defineProperty(_$$slick2, "responsive", [{
    breakpoint: 768,
    settings: 'unslick'
  }]), _$$slick2));
  $(".partners__partners").slick((_$$slick3 = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 3000,
    autoplay: true
  }, _defineProperty(_$$slick3, "arrows", false), _defineProperty(_$$slick3, "centerMode", true), _defineProperty(_$$slick3, "slidesToShow", 2), _defineProperty(_$$slick3, "autoplaySpeed", 0), _defineProperty(_$$slick3, "cssEase", "linear"), _defineProperty(_$$slick3, "lazyLoad", "ondemand"), _defineProperty(_$$slick3, "mobileFirst", true), _defineProperty(_$$slick3, "responsive", [{
    breakpoint: 768,
    settings: 'unslick'
  }]), _$$slick3));

  // BOOTSTRAP MENU
  $(".menu-dropdown-toggle").on("click", function () {
    $(this).toggleClass("open");
    $(this).siblings("ul").toggleClass("open");
  });
  $("button.navbar-toggler").on("click", function (event) {
    $(".site-header").toggleClass("open");
  });

  // REPLACE ALL SVG IMAGES WITH INLINE SVG
  $("img.svg").each(function () {
    var $img = $(this);
    var imgID = $img.attr("id");
    var imgClass = $img.attr("class");
    var imgURL = $img.attr("src");
    $.get(imgURL, function (data) {
      //Get the SVG tag, ignore the rest
      var $svg = $(data).find("svg");
      //Add replaced image's ID to the new SVG
      if (typeof imgID !== "undefined") {
        $svg = $svg.attr("id", imgID);
      }
      //Add replaced image's classes to the new SVG
      if (typeof imgClass !== "undefined") {
        $svg = $svg.attr("class", imgClass + " replaced-svg");
      }
      //Remove any invalid XML tags as per http:validator.w3.org
      $svg = $svg.removeAttr("xmlns:a");
      // Replace image with new SVG
      $img.replaceWith($svg);
    });
  });
  $(function () {
    $(".lazy").lazy();
  });

  // OTHER PLACEHOLDER
  $(".frm_other_input").attr("placeholder", "Enter other option");

  // FORM TEXT TICK
  $(".frm_forms input[type!=\"radio\"][type!=\"checkbox\"]").on("change", function () {
    window.dispatchEvent(new Event("resize"));
    checkInputValid($(this));
  });

  // FORM SELECT TICK
  $(".frm_forms select").on("change", function () {
    window.dispatchEvent(new Event("resize"));
    checkSelectInputValid($(this));
  });

  // FORM OTHER INPUT TICK
  $(".frm_forms .frm_other_input").on("change", function () {
    window.dispatchEvent(new Event("resize"));
    checkOtherInputValid($(this));
  });

  // FORM RADIO TICK
  $(".frm_forms input[type=\"radio\"]").on("change", function () {
    window.dispatchEvent(new Event("resize"));
    checkRadioInputValid($(this));
  });

  // FORM CHECKBOX TICK
  $(".frm_forms input[type=\"checkbox\"]").on("change", function () {
    window.dispatchEvent(new Event("resize"));
    checkCheckboxInputValid($(this));
  });

  // FORM TICKS ON LOAD
  $(".frm_forms input[type!=\"radio\"][type!=\"checkbox\"]").each(function () {
    checkInputValid($(this));
  });
  $(".frm_forms select").each(function () {
    checkSelectInputValid($(this));
  });
  $(".frm_forms .frm_other_input").each(function () {
    checkOtherInputValid($(this));
  });
  $(".frm_forms input[type=\"radio\"]").each(function () {
    checkRadioInputValid($(this));
  });
  $(".frm_forms input[type=\"checkbox\"]").each(function () {
    checkCheckboxInputValid($(this));
  });
  function checkInputValid($field) {
    if ($field.val() !== "" && $field.val() !== null && $field.is(":valid")) {
      $field.closest(".frm_form_field").addClass("valid-input");
    } else {
      $field.closest(".frm_form_field").removeClass("valid-input");
    }
  }
  function checkSelectInputValid($selectField) {
    if ($selectField.val() !== "" && $selectField.val() !== null && $selectField.val() !== undefined && $selectField.val() !== "Other") {
      $selectField.closest(".frm_form_field").addClass("valid-input");
    } else {
      $selectField.closest(".frm_form_field").removeClass("valid-input");
    }
  }
  function checkOtherInputValid($field) {
    if ($field.val() !== "" && $field.val() !== null && $field.is(":valid")) {
      $field.closest(".frm_form_field").addClass("valid-input");
    } else {
      $field.closest(".frm_form_field").removeClass("valid-input");
    }
  }
  function checkRadioInputValid($radioField) {
    if ($radioField.val() !== "" && $radioField.val() !== null && $radioField.val() !== undefined && $radioField.is(":checked")) {
      $radioField.closest(".frm_form_field").addClass("valid-input");
    } else {
      $radioField.closest(".frm_form_field").removeClass("valid-input");
    }
  }
  function checkCheckboxInputValid($checkboxField) {
    if ($checkboxField.val() !== "" && $checkboxField.val() !== null && $checkboxField.val() !== undefined && $checkboxField.is(":checked")) {
      $checkboxField.closest(".frm_form_field").addClass("valid-input");
    } else {
      $checkboxField.closest(".frm_form_field").removeClass("valid-input");
    }
    var name = $checkboxField.attr("name");
    $(".frm_forms input[name=\"" + name + "\"]").each(function () {
      if ($(this).is(":checked")) {
        $(this).closest(".frm_form_field").addClass("valid-input");
      }
    });
  }

  // HIDE SIDEBAR ON NON-PAGE-1
  function checkMobileSidebar() {
    if (!$(".with_frm_style .frm_form_fields").hasClass("frm_page_num_1")) {
      if (window.matchMedia("(max-width:767.98px)").matches) {
        $(".orderform__sidebar.orderform__sidebar--top .orderform__sidebar-list").hide();
      } else {
        $(".orderform__sidebar.orderform__sidebar--top .orderform__sidebar-list").show();
      }
    }
  }
  checkMobileSidebar();
  $(window).on("resize", function () {
    checkMobileSidebar();
  });
  function activeTab() {
    $('.tab__image').addClass('hide');
    var activeTab = $('.nav-tabs .nav-link.active').data('tab');
    $('.tab__image img').each(function () {
      var imgDataId = $(this).data('tab');
      if (activeTab === imgDataId) {
        $(this).closest(".tab__image").removeClass('hide');
        $(this).closest(".tab__image").addClass('show');
      }
    });
  }
  activeTab();
  $('.nav-tabs .nav-link').click(function () {
    var navLinkId = $(this).data('tab');
    $('.tab__image').addClass('hide');
    $('.show').removeClass('show');
    $('.tab__image img').each(function () {
      var imgDataId = $(this).data('tab');
      if (navLinkId === imgDataId) {
        $(this).closest(".tab__image").removeClass('hide');
        $(this).closest(".tab__image").addClass('show');
      }
    });
  });

  // SECTION TITLES
  $(".page-template-template-orderform-single .orderform .frm_style_formidable-style.with_frm_style .frm-show-form .frm_section_heading h3").each(function () {
    if ($(this).text() == "") {
      // $(this).css('display', 'none');
      $(this).remove();
    }
  });

  // SECTION PARENT/CHILD
  // $('.page-template-template-orderform-single .orderform .frm_style_formidable-style.with_frm_style .frm-show-form .frm_section_heading--child').each(function() {
  //     $(this).appendTo('.page-template-template-orderform-single .orderform .frm_style_formidable-style.with_frm_style .frm-show-form .frm_section_heading--parent');
  // });

  // SELECT FONT COLOUR
  function checkSelectFontColour() {
    $(".frm_forms select").each(function () {
      if ($(this).val() == "" || $(this).val() == null || $(this).val() == undefined) {
        $(this).css("color", "#A1A1A1");
      } else {
        $(this).css("color", "");
      }
    });
  }
  checkSelectFontColour();
  $(".frm_forms select").on("change", function () {
    $(this).css("color", "");
  });

  // RADIO active state for label
  $(".frm_forms input[type=\"radio\"]").on("change", function () {
    checkActiveRadio($(this));
  });
  $(".frm_forms input[type=\"radio\"]").each(function () {
    checkActiveRadio($(this));
  });
  function checkActiveRadio($radioField) {
    var name = $radioField.attr("name");
    $(".frm_forms input[name=\"" + name + "\"]").closest("label").removeClass("active");
    if ($radioField.is(":checked")) {
      $radioField.closest("label").addClass("active");
    }
  }

  // MOVE NAV
  if ($(".page-template-template-orderform-single .orderform .frm_rootline_group").length > 0) {
    $(".page-template-template-orderform-single .orderform .frm_rootline_group").clone().prependTo(".orderform__nav .col");
    $(".page-template-template-orderform-single .orderform .frm_forms form .frm_rootline_group").css("display", "none");
    $(".page-template-template-orderform-single .orderform__nav").addClass("active");
    if (checkNavTitlesOverlapping()) {
      $(".page-template-template-orderform-single .orderform__nav").removeClass("show-titles");
    } else {
      $(".page-template-template-orderform-single .orderform__nav").addClass("show-titles");
    }
  }

  // ADD .frm_old_page TO .frm_rootline_single
  if ($(".page-template-template-orderform-single .orderform__nav .frm_rootline_group ul.frm_page_bar li").length > 0) {
    var currentSeen = false;
    $(".page-template-template-orderform-single .orderform__nav .frm_rootline_group ul.frm_page_bar li").each(function () {
      if ($(this).hasClass("frm_current_page")) {
        currentSeen = true;
      }
      if (!currentSeen && !$(this).hasClass("frm_current_page")) {
        $(this).addClass("frm_old_page");
      }
    });
  }

  // CHECK NAV TITLES
  function checkNavTitlesOverlapping() {
    var returnVal = false;
    $(".page-template-template-orderform-single .orderform .orderform__nav .frm_rootline_group ul.frm_page_bar li").each(function () {
      var $next = $(this).next("li");
      if ($next) {
        var thisTitle = $(this).find(".frm_rootline_title");
        var nextTitle = $next.find(".frm_rootline_title");
        if (thisTitle.length > 0 && nextTitle.length > 0 && checkElementsOverlap(thisTitle.get(0), nextTitle.get(0))) {
          returnVal = true;
        }
      }
    });
    return returnVal;
  }
  jQuery(window).on("resize", function () {
    if (checkNavTitlesOverlapping()) {
      $(".page-template-template-orderform-single .orderform__nav").removeClass("show-titles");
    } else {
      $(".page-template-template-orderform-single .orderform__nav").addClass("show-titles");
    }
  });
  function checkElementsOverlap(el1, el2) {
    var domRect1 = el1.getBoundingClientRect();
    var domRect2 = el2.getBoundingClientRect();
    return !(domRect1.top > domRect2.bottom || domRect1.right < domRect2.left || domRect1.bottom < domRect2.top || domRect1.left > domRect2.right);
  }

  // NAV PROGRESS BAR
  if ($(".page-template-template-orderform-single .orderform .orderform__nav .frm_rootline_group").length > 0) {
    // console.log('test1');

    var total = 0;
    var current = 0;
    $(".page-template-template-orderform-single .orderform__nav .frm_rootline_group ul.frm_page_bar li:not(.frm_hidden)").each(function () {
      total++;
      if ($(this).hasClass("frm_current_page")) {
        current = total;
      }
    });

    // if first
    if (current == 1) {
      $(".page-template-template-orderform-single .orderform__nav .orderform__progress-bar").css("transform", "translateX(-102%)");
    }

    // console.log('current = ' + current);
    // console.log('total = ' + total);

    // if not end
    if (current !== total) {
      var startingPercentge = 100 / (total - 1) * (current - 1);
      var endingPercentage = 100 / (total - 1) * current;
      var workingRange = endingPercentage - startingPercentge;
      $(window).on("scroll", function () {
        // console.log('scroll1');

        var scroll = $(window).scrollTop();
        var orderformOffset = $(".orderform").offset().top;
        var orderformHeight = $(".orderform").innerHeight() - 300;
        var scrolled = scroll / (orderformOffset + orderformHeight - 100) * 100;
        if (scrolled > 100 || scroll > orderformOffset + orderformHeight) {
          scrolled = 100;
        }
        var transformOffset = -100 + startingPercentge + scrolled / 100 * workingRange;

        // console.log('transformOffset = ' + transformOffset);

        $(".page-template-template-orderform-single .orderform__progress-bar").css("transform", "translateX(" + transformOffset + "%)");
      });
    }

    // if end
    if (current == total) {
      $(".page-template-template-orderform-single .orderform__progress-bar").css("transform", "translateX(0)");
    }
    window.dispatchEvent(new Event("scroll"));
  }

  // DROPDOWN
  $(".frm_style_formidable-style.with_frm_style .frm-show-form .frm_form_fields .frm_section_heading--child h3.frm_trigger").on("click", function (e) {
    e.preventDefault();
    window.dispatchEvent(new Event("resize"));
    if (!$(this).hasClass("active")) {
      var $parent = $(this).parents(".frm_section_heading--child");
      $(".frm_style_formidable-style.with_frm_style .frm-show-form .frm_form_fields .frm_section_heading--child").each(function () {
        if ($(this) !== $parent || $(this).children("h3.frm_trigger").hasClass("active")) {
          $(this).children("h3.frm_trigger").removeClass("active");
          $(this).children(".frm_toggle_container.frm_grid_container").removeClass("active");
        }
      });
    }
  });
});

// OBJECTFIT JS
/*! modernizr 3.6.0 (Custom Build) | MIT *
 * https://modernizr.com/download/?-objectfit-setclasses !*/
!function (e, n, t) {
  function r(e, n) {
    return _typeof(e) === n;
  }
  function o() {
    var e, n, t, o, i, s, a;
    for (var l in C) if (C.hasOwnProperty(l)) {
      if (e = [], n = C[l], n.name && (e.push(n.name.toLowerCase()), n.options && n.options.aliases && n.options.aliases.length)) for (t = 0; t < n.options.aliases.length; t++) e.push(n.options.aliases[t].toLowerCase());
      for (o = r(n.fn, "function") ? n.fn() : n.fn, i = 0; i < e.length; i++) s = e[i], a = s.split("."), 1 === a.length ? Modernizr[a[0]] = o : (!Modernizr[a[0]] || Modernizr[a[0]] instanceof Boolean || (Modernizr[a[0]] = new Boolean(Modernizr[a[0]])), Modernizr[a[0]][a[1]] = o), h.push((o ? "" : "no-") + a.join("-"));
    }
  }
  function i(e) {
    var n = _.className,
      t = Modernizr._config.classPrefix || "";
    if (w && (n = n.baseVal), Modernizr._config.enableJSClass) {
      var r = new RegExp("(^|\\s)" + t + "no-js(\\s|$)");
      n = n.replace(r, "$1" + t + "js$2");
    }
    Modernizr._config.enableClasses && (n += " " + t + e.join(" " + t), w ? _.className.baseVal = n : _.className = n);
  }
  function s(e) {
    return e.replace(/([a-z])-([a-z])/g, function (e, n, t) {
      return n + t.toUpperCase();
    }).replace(/^-/, "");
  }
  function a(e, n) {
    return !!~("" + e).indexOf(n);
  }
  function l() {
    return "function" != typeof n.createElement ? n.createElement(arguments[0]) : w ? n.createElementNS.call(n, "http://www.w3.org/2000/svg", arguments[0]) : n.createElement.apply(n, arguments);
  }
  function f(e, n) {
    return function () {
      return e.apply(n, arguments);
    };
  }
  function u(e, n, t) {
    var o;
    for (var i in e) if (e[i] in n) return t === !1 ? e[i] : (o = n[e[i]], r(o, "function") ? f(o, t || n) : o);
    return !1;
  }
  function p(n, t, r) {
    var o;
    if ("getComputedStyle" in e) {
      o = getComputedStyle.call(e, n, t);
      var i = e.console;
      if (null !== o) r && (o = o.getPropertyValue(r));else if (i) {
        var s = i.error ? "error" : "log";
        i[s].call(i, "getComputedStyle returning null, its possible modernizr test results are inaccurate");
      }
    } else o = !t && n.currentStyle && n.currentStyle[r];
    return o;
  }
  function c(e) {
    return e.replace(/([A-Z])/g, function (e, n) {
      return "-" + n.toLowerCase();
    }).replace(/^ms-/, "-ms-");
  }
  function d() {
    var e = n.body;
    return e || (e = l(w ? "svg" : "body"), e.fake = !0), e;
  }
  function m(e, t, r, o) {
    var i,
      s,
      a,
      f,
      u = "modernizr",
      p = l("div"),
      c = d();
    if (parseInt(r, 10)) for (; r--;) a = l("div"), a.id = o ? o[r] : u + (r + 1), p.appendChild(a);
    return i = l("style"), i.type = "text/css", i.id = "s" + u, (c.fake ? c : p).appendChild(i), c.appendChild(p), i.styleSheet ? i.styleSheet.cssText = e : i.appendChild(n.createTextNode(e)), p.id = u, c.fake && (c.style.background = "", c.style.overflow = "hidden", f = _.style.overflow, _.style.overflow = "hidden", _.appendChild(c)), s = t(p, e), c.fake ? (c.parentNode.removeChild(c), _.style.overflow = f, _.offsetHeight) : p.parentNode.removeChild(p), !!s;
  }
  function v(n, r) {
    var o = n.length;
    if ("CSS" in e && "supports" in e.CSS) {
      for (; o--;) if (e.CSS.supports(c(n[o]), r)) return !0;
      return !1;
    }
    if ("CSSSupportsRule" in e) {
      for (var i = []; o--;) i.push("(" + c(n[o]) + ":" + r + ")");
      return i = i.join(" or "), m("@supports (" + i + ") { #modernizr { position: absolute; } }", function (e) {
        return "absolute" == p(e, null, "position");
      });
    }
    return t;
  }
  function y(e, n, o, i) {
    function f() {
      p && (delete P.style, delete P.modElem);
    }
    if (i = r(i, "undefined") ? !1 : i, !r(o, "undefined")) {
      var u = v(e, o);
      if (!r(u, "undefined")) return u;
    }
    for (var p, c, d, m, y, g = ["modernizr", "tspan", "samp"]; !P.style && g.length;) p = !0, P.modElem = l(g.shift()), P.style = P.modElem.style;
    for (d = e.length, c = 0; d > c; c++) if (m = e[c], y = P.style[m], a(m, "-") && (m = s(m)), P.style[m] !== t) {
      if (i || r(o, "undefined")) return f(), "pfx" == n ? m : !0;
      try {
        P.style[m] = o;
      } catch (h) {}
      if (P.style[m] != y) return f(), "pfx" == n ? m : !0;
    }
    return f(), !1;
  }
  function g(e, n, t, o, i) {
    var s = e.charAt(0).toUpperCase() + e.slice(1),
      a = (e + " " + b.join(s + " ") + s).split(" ");
    return r(n, "string") || r(n, "undefined") ? y(a, n, o, i) : (a = (e + " " + j.join(s + " ") + s).split(" "), u(a, n, t));
  }
  var h = [],
    C = [],
    S = {
      _version: "3.6.0",
      _config: {
        classPrefix: "",
        enableClasses: !0,
        enableJSClass: !0,
        usePrefixes: !0
      },
      _q: [],
      on: function on(e, n) {
        var t = this;
        setTimeout(function () {
          n(t[e]);
        }, 0);
      },
      addTest: function addTest(e, n, t) {
        C.push({
          name: e,
          fn: n,
          options: t
        });
      },
      addAsyncTest: function addAsyncTest(e) {
        C.push({
          name: null,
          fn: e
        });
      }
    },
    Modernizr = function Modernizr() {};
  Modernizr.prototype = S, Modernizr = new Modernizr();
  var _ = n.documentElement,
    w = "svg" === _.nodeName.toLowerCase(),
    x = "Moz O ms Webkit",
    b = S._config.usePrefixes ? x.split(" ") : [];
  S._cssomPrefixes = b;
  var E = function E(n) {
    var r,
      o = prefixes.length,
      i = e.CSSRule;
    if ("undefined" == typeof i) return t;
    if (!n) return !1;
    if (n = n.replace(/^@/, ""), r = n.replace(/-/g, "_").toUpperCase() + "_RULE", r in i) return "@" + n;
    for (var s = 0; o > s; s++) {
      var a = prefixes[s],
        l = a.toUpperCase() + "_" + r;
      if (l in i) return "@-" + a.toLowerCase() + "-" + n;
    }
    return !1;
  };
  S.atRule = E;
  var j = S._config.usePrefixes ? x.toLowerCase().split(" ") : [];
  S._domPrefixes = j;
  var z = {
    elem: l("modernizr")
  };
  Modernizr._q.push(function () {
    delete z.elem;
  });
  var P = {
    style: z.elem.style
  };
  Modernizr._q.unshift(function () {
    delete P.style;
  }), S.testAllProps = g;
  var N = S.prefixed = function (e, n, t) {
    return 0 === e.indexOf("@") ? E(e) : (-1 != e.indexOf("-") && (e = s(e)), n ? g(e, n, t) : g(e, "pfx"));
  };
  Modernizr.addTest("objectfit", !!N("objectFit"), {
    aliases: ["object-fit"]
  }), o(), i(h), delete S.addTest, delete S.addAsyncTest;
  for (var T = 0; T < Modernizr._q.length; T++) Modernizr._q[T]();
  e.Modernizr = Modernizr;
}(window, document);

// OBJECTFIT BACKGROUND IMAGE
jQuery(document).ready(function ($) {
  if (!Modernizr.objectfit) {
    jQuery("div.objectfit").each(function () {
      var $container = jQuery(this),
        imgUrl = $container.find("img").attr("data-original-src");
      if (imgUrl) {
        $container.css("backgroundImage", "url(" + imgUrl + ")").addClass("compat-object-fit");
      }
    });
  }

  // OTHER PLACEHOLDER
  $(".frm_other_input").attr("placeholder", "Enter other option");

  // FORM TEXT TICK
  $(".frm_forms input[type!=\"radio\"][type!=\"checkbox\"]").on("change", function () {
    window.dispatchEvent(new Event("resize"));
    checkInputValid($(this));
  });

  // FORM SELECT TICK
  $(".frm_forms select").on("change", function () {
    window.dispatchEvent(new Event("resize"));
    checkSelectInputValid($(this));
  });

  // FORM OTHER INPUT TICK
  $(".frm_forms .frm_other_input").on("change", function () {
    window.dispatchEvent(new Event("resize"));
    checkOtherInputValid($(this));
  });

  // FORM RADIO TICK
  $(".frm_forms input[type=\"radio\"]").on("change", function () {
    window.dispatchEvent(new Event("resize"));
    checkRadioInputValid($(this));
  });

  // FORM CHECKBOX TICK
  $(".frm_forms input[type=\"checkbox\"]").on("change", function () {
    window.dispatchEvent(new Event("resize"));
    checkCheckboxInputValid($(this));
  });

  // FORM TICKS ON LOAD
  $(".frm_forms input[type!=\"radio\"][type!=\"checkbox\"]").each(function () {
    checkInputValid($(this));
  });
  $(".frm_forms select").each(function () {
    checkSelectInputValid($(this));
  });
  $(".frm_forms .frm_other_input").each(function () {
    checkOtherInputValid($(this));
  });
  $(".frm_forms input[type=\"radio\"]").each(function () {
    checkRadioInputValid($(this));
  });
  $(".frm_forms input[type=\"checkbox\"]").each(function () {
    checkCheckboxInputValid($(this));
  });
  function checkInputValid($field) {
    if ($field.val() !== "" && $field.val() !== null && $field.is(":valid")) {
      $field.closest(".frm_form_field").addClass("valid-input");
    } else {
      $field.closest(".frm_form_field").removeClass("valid-input");
    }
  }
  function checkSelectInputValid($selectField) {
    if ($selectField.val() !== "" && $selectField.val() !== null && $selectField.val() !== undefined && $selectField.val() !== "Other") {
      $selectField.closest(".frm_form_field").addClass("valid-input");
    } else {
      $selectField.closest(".frm_form_field").removeClass("valid-input");
    }
  }
  function checkOtherInputValid($field) {
    if ($field.val() !== "" && $field.val() !== null && $field.is(":valid")) {
      $field.closest(".frm_form_field").addClass("valid-input");
    } else {
      $field.closest(".frm_form_field").removeClass("valid-input");
    }
  }
  function checkRadioInputValid($radioField) {
    if ($radioField.val() !== "" && $radioField.val() !== null && $radioField.val() !== undefined && $radioField.is(":checked")) {
      $radioField.closest(".frm_form_field").addClass("valid-input");
    } else {
      $radioField.closest(".frm_form_field").removeClass("valid-input");
    }
  }
  function checkCheckboxInputValid($checkboxField) {
    if ($checkboxField.val() !== "" && $checkboxField.val() !== null && $checkboxField.val() !== undefined && $checkboxField.is(":checked")) {
      $checkboxField.closest(".frm_form_field").addClass("valid-input");
    } else {
      $checkboxField.closest(".frm_form_field").removeClass("valid-input");
    }
    var name = $checkboxField.attr("name");
    $(".frm_forms input[name=\"" + name + "\"]").each(function () {
      if ($(this).is(":checked")) {
        $(this).closest(".frm_form_field").addClass("valid-input");
      }
    });
  }

  // HIDE SIDEBAR ON NON-PAGE-1
  function checkMobileSidebar() {
    if (!$(".with_frm_style .frm_form_fields").hasClass("frm_page_num_1")) {
      if (window.matchMedia("(max-width:767.98px)").matches) {
        $(".orderform__sidebar.orderform__sidebar--top .orderform__sidebar-list").hide();
      } else {
        $(".orderform__sidebar.orderform__sidebar--top .orderform__sidebar-list").show();
      }
    }
  }
  checkMobileSidebar();
  $(window).on("resize", function () {
    checkMobileSidebar();
  });

  // SECTION TITLES
  $(".page-template-template-orderform-single .orderform .frm_style_formidable-style.with_frm_style .frm-show-form .frm_section_heading h3").each(function () {
    if ($(this).text() == "") {
      // $(this).css('display', 'none');
      $(this).remove();
    }
  });

  // SECTION PARENT/CHILD
  // $('.page-template-template-orderform-single .orderform .frm_style_formidable-style.with_frm_style .frm-show-form .frm_section_heading--child').each(function() {
  //     $(this).appendTo('.page-template-template-orderform-single .orderform .frm_style_formidable-style.with_frm_style .frm-show-form .frm_section_heading--parent');
  // });

  // SELECT FONT COLOUR
  function checkSelectFontColour() {
    $(".frm_forms select").each(function () {
      if ($(this).val() == "" || $(this).val() == null || $(this).val() == undefined) {
        $(this).css("color", "#A1A1A1");
      } else {
        $(this).css("color", "");
      }
    });
  }
  checkSelectFontColour();
  $(".frm_forms select").on("change", function () {
    $(this).css("color", "");
  });

  // RADIO active state for label
  $(".frm_forms input[type=\"radio\"]").on("change", function () {
    checkActiveRadio($(this));
  });
  $(".frm_forms input[type=\"radio\"]").each(function () {
    checkActiveRadio($(this));
  });
  function checkActiveRadio($radioField) {
    var name = $radioField.attr("name");
    $(".frm_forms input[name=\"" + name + "\"]").closest("label").removeClass("active");
    if ($radioField.is(":checked")) {
      $radioField.closest("label").addClass("active");
    }
  }

  // MOVE NAV
  if ($(".page-template-template-orderform-single .orderform .frm_rootline_group").length > 0) {
    $(".page-template-template-orderform-single .orderform .frm_rootline_group").clone().prependTo(".orderform__nav .col");
    $(".page-template-template-orderform-single .orderform .frm_forms form .frm_rootline_group").css("display", "none");
    $(".page-template-template-orderform-single .orderform__nav").addClass("active");
    if (checkNavTitlesOverlapping()) {
      $(".page-template-template-orderform-single .orderform__nav").removeClass("show-titles");
    } else {
      $(".page-template-template-orderform-single .orderform__nav").addClass("show-titles");
    }
  }

  // ADD .frm_old_page TO .frm_rootline_single
  if ($(".page-template-template-orderform-single .orderform__nav .frm_rootline_group ul.frm_page_bar li").length > 0) {
    var currentSeen = false;
    $(".page-template-template-orderform-single .orderform__nav .frm_rootline_group ul.frm_page_bar li").each(function () {
      if ($(this).hasClass("frm_current_page")) {
        currentSeen = true;
      }
      if (!currentSeen && !$(this).hasClass("frm_current_page")) {
        $(this).addClass("frm_old_page");
      }
    });
  }

  // CHECK NAV TITLES
  function checkNavTitlesOverlapping() {
    var returnVal = false;
    $(".page-template-template-orderform-single .orderform .orderform__nav .frm_rootline_group ul.frm_page_bar li").each(function () {
      var $next = $(this).next("li");
      if ($next) {
        var thisTitle = $(this).find(".frm_rootline_title");
        var nextTitle = $next.find(".frm_rootline_title");
        if (thisTitle.length > 0 && nextTitle.length > 0 && checkElementsOverlap(thisTitle.get(0), nextTitle.get(0))) {
          returnVal = true;
        }
      }
    });
    return returnVal;
  }
  jQuery(window).on("resize", function () {
    if (checkNavTitlesOverlapping()) {
      $(".page-template-template-orderform-single .orderform__nav").removeClass("show-titles");
    } else {
      $(".page-template-template-orderform-single .orderform__nav").addClass("show-titles");
    }
  });
  function checkElementsOverlap(el1, el2) {
    var domRect1 = el1.getBoundingClientRect();
    var domRect2 = el2.getBoundingClientRect();
    return !(domRect1.top > domRect2.bottom || domRect1.right < domRect2.left || domRect1.bottom < domRect2.top || domRect1.left > domRect2.right);
  }

  // NAV PROGRESS BAR
  if ($(".page-template-template-orderform-single .orderform .orderform__nav .frm_rootline_group").length > 0) {
    // console.log('test1');

    var total = 0;
    var current = 0;
    $(".page-template-template-orderform-single .orderform__nav .frm_rootline_group ul.frm_page_bar li:not(.frm_hidden)").each(function () {
      total++;
      if ($(this).hasClass("frm_current_page")) {
        current = total;
      }
    });

    // if first
    if (current == 1) {
      $(".page-template-template-orderform-single .orderform__nav .orderform__progress-bar").css("transform", "translateX(-102%)");
    }

    // console.log('current = ' + current);
    // console.log('total = ' + total);

    // if not end
    if (current !== total) {
      var startingPercentge = 100 / (total - 1) * (current - 1);
      var endingPercentage = 100 / (total - 1) * current;
      var workingRange = endingPercentage - startingPercentge;
      $(window).on("scroll", function () {
        // console.log('scroll1');

        var scroll = $(window).scrollTop();
        var orderformOffset = $(".orderform").offset().top;
        var orderformHeight = $(".orderform").innerHeight() - 300;
        var scrolled = scroll / (orderformOffset + orderformHeight - 100) * 100;
        if (scrolled > 100 || scroll > orderformOffset + orderformHeight) {
          scrolled = 100;
        }
        var transformOffset = -100 + startingPercentge + scrolled / 100 * workingRange;

        // console.log('transformOffset = ' + transformOffset);

        $(".page-template-template-orderform-single .orderform__progress-bar").css("transform", "translateX(" + transformOffset + "%)");
      });
    }

    // if end
    if (current == total) {
      $(".page-template-template-orderform-single .orderform__progress-bar").css("transform", "translateX(0)");
    }
    window.dispatchEvent(new Event("scroll"));
  }

  // DROPDOWN
  $(".frm_style_formidable-style.with_frm_style .frm-show-form .frm_form_fields .frm_section_heading--child h3.frm_trigger").on("click", function (e) {
    e.preventDefault();
    console.log("h3.frm_trigger click");
    window.dispatchEvent(new Event("resize"));
    if (!$(this).hasClass("active")) {
      console.log("h3.frm_trigger does not have class active");
      var $parent = $(this).parents(".frm_section_heading--child");
      $(".frm_style_formidable-style.with_frm_style .frm-show-form .frm_form_fields .frm_section_heading--child").each(function () {
        if ($(this) !== $parent || $(this).children("h3.frm_trigger").hasClass("active")) {
          $(this).children("h3.frm_trigger").removeClass("active");
          $(this).children(".frm_toggle_container.frm_grid_container").removeClass("active");
        }
      });
    }
  });
});
$ = jQuery.noConflict();
var doc = document.documentElement;
doc.setAttribute("data-useragent", navigator.userAgent);

/*! jQuery & Zepto Lazy v1.7.10 - http://jquery.eisbehr.de/lazy - MIT&GPL-2.0 license - Copyright 2012-2018 Daniel 'Eisbehr' Kern */
!function (t, e) {
  "use strict";

  function r(r, a, i, u, l) {
    function f() {
      L = t.devicePixelRatio > 1, i = c(i), a.delay >= 0 && setTimeout(function () {
        s(!0);
      }, a.delay), (a.delay < 0 || a.combined) && (u.e = v(a.throttle, function (t) {
        "resize" === t.type && (w = B = -1), s(t.all);
      }), u.a = function (t) {
        t = c(t), i.push.apply(i, t);
      }, u.g = function () {
        return i = n(i).filter(function () {
          return !n(this).data(a.loadedName);
        });
      }, u.f = function (t) {
        for (var e = 0; e < t.length; e++) {
          var r = i.filter(function () {
            return this === t[e];
          });
          r.length && s(!1, r);
        }
      }, s(), n(a.appendScroll).on("scroll." + l + " resize." + l, u.e));
    }
    function c(t) {
      var i = a.defaultImage,
        o = a.placeholder,
        u = a.imageBase,
        l = a.srcsetAttribute,
        f = a.loaderAttribute,
        c = a._f || {};
      t = n(t).filter(function () {
        var t = n(this),
          r = m(this);
        return !t.data(a.handledName) && (t.attr(a.attribute) || t.attr(l) || t.attr(f) || c[r] !== e);
      }).data("plugin_" + a.name, r);
      for (var s = 0, d = t.length; s < d; s++) {
        var A = n(t[s]),
          g = m(t[s]),
          h = A.attr(a.imageBaseAttribute) || u;
        g === N && h && A.attr(l) && A.attr(l, b(A.attr(l), h)), c[g] === e || A.attr(f) || A.attr(f, c[g]), g === N && i && !A.attr(E) ? A.attr(E, i) : g === N || !o || A.css(O) && "none" !== A.css(O) || A.css(O, "url('" + o + "')");
      }
      return t;
    }
    function s(t, e) {
      if (!i.length) return void (a.autoDestroy && r.destroy());
      for (var o = e || i, u = !1, l = a.imageBase || "", f = a.srcsetAttribute, c = a.handledName, s = 0; s < o.length; s++) if (t || e || A(o[s])) {
        var g = n(o[s]),
          h = m(o[s]),
          b = g.attr(a.attribute),
          v = g.attr(a.imageBaseAttribute) || l,
          p = g.attr(a.loaderAttribute);
        g.data(c) || a.visibleOnly && !g.is(":visible") || !((b || g.attr(f)) && (h === N && (v + b !== g.attr(E) || g.attr(f) !== g.attr(F)) || h !== N && v + b !== g.css(O)) || p) || (u = !0, g.data(c, !0), d(g, h, v, p));
      }
      u && (i = n(i).filter(function () {
        return !n(this).data(c);
      }));
    }
    function d(t, e, r, i) {
      ++z;
      var _o = function o() {
        y("onError", t), p(), _o = n.noop;
      };
      y("beforeLoad", t);
      var u = a.attribute,
        l = a.srcsetAttribute,
        f = a.sizesAttribute,
        c = a.retinaAttribute,
        s = a.removeAttribute,
        d = a.loadedName,
        A = t.attr(c);
      if (i) {
        var _g = function g() {
          s && t.removeAttr(a.loaderAttribute), t.data(d, !0), y(T, t), setTimeout(p, 1), _g = n.noop;
        };
        t.off(I).one(I, _o).one(D, _g), y(i, t, function (e) {
          e ? (t.off(D), _g()) : (t.off(I), _o());
        }) || t.trigger(I);
      } else {
        var h = n(new Image());
        h.one(I, _o).one(D, function () {
          t.hide(), e === N ? t.attr(C, h.attr(C)).attr(F, h.attr(F)).attr(E, h.attr(E)) : t.css(O, "url('" + h.attr(E) + "')"), t[a.effect](a.effectTime), s && (t.removeAttr(u + " " + l + " " + c + " " + a.imageBaseAttribute), f !== C && t.removeAttr(f)), t.data(d, !0), y(T, t), h.remove(), p();
        });
        var m = (L && A ? A : t.attr(u)) || "";
        h.attr(C, t.attr(f)).attr(F, t.attr(l)).attr(E, m ? r + m : null), h.complete && h.trigger(D);
      }
    }
    function A(t) {
      var e = t.getBoundingClientRect(),
        r = a.scrollDirection,
        n = a.threshold,
        i = h() + n > e.top && -n < e.bottom,
        o = g() + n > e.left && -n < e.right;
      return "vertical" === r ? i : "horizontal" === r ? o : i && o;
    }
    function g() {
      return w >= 0 ? w : w = n(t).width();
    }
    function h() {
      return B >= 0 ? B : B = n(t).height();
    }
    function m(t) {
      return t.tagName.toLowerCase();
    }
    function b(t, e) {
      if (e) {
        var r = t.split(",");
        t = "";
        for (var a = 0, n = r.length; a < n; a++) t += e + r[a].trim() + (a !== n - 1 ? "," : "");
      }
      return t;
    }
    function v(t, e) {
      var n,
        i = 0;
      return function (o, u) {
        function l() {
          i = +new Date(), e.call(r, o);
        }
        var f = +new Date() - i;
        n && clearTimeout(n), f > t || !a.enableThrottle || u ? l() : n = setTimeout(l, t - f);
      };
    }
    function p() {
      --z, i.length || z || y("onFinishedAll");
    }
    function y(t, e, n) {
      return !!(t = a[t]) && (t.apply(r, [].slice.call(arguments, 1)), !0);
    }
    var z = 0,
      w = -1,
      B = -1,
      L = !1,
      T = "afterLoad",
      D = "load",
      I = "error",
      N = "img",
      E = "src",
      F = "srcset",
      C = "sizes",
      O = "background-image";
    "event" === a.bind || o ? f() : n(t).on(D + "." + l, f);
  }
  function a(a, o) {
    var u = this,
      l = n.extend({}, u.config, o),
      f = {},
      c = l.name + "-" + ++i;
    return u.config = function (t, r) {
      return r === e ? l[t] : (l[t] = r, u);
    }, u.addItems = function (t) {
      return f.a && f.a("string" === n.type(t) ? n(t) : t), u;
    }, u.getItems = function () {
      return f.g ? f.g() : {};
    }, u.update = function (t) {
      return f.e && f.e({}, !t), u;
    }, u.force = function (t) {
      return f.f && f.f("string" === n.type(t) ? n(t) : t), u;
    }, u.loadAll = function () {
      return f.e && f.e({
        all: !0
      }, !0), u;
    }, u.destroy = function () {
      return n(l.appendScroll).off("." + c, f.e), n(t).off("." + c), f = {}, e;
    }, r(u, l, a, f, c), l.chainable ? a : u;
  }
  var n = t.jQuery || t.Zepto,
    i = 0,
    o = !1;
  n.fn.Lazy = n.fn.lazy = function (t) {
    return new a(this, t);
  }, n.Lazy = n.lazy = function (t, r, i) {
    if (n.isFunction(r) && (i = r, r = []), n.isFunction(i)) {
      t = n.isArray(t) ? t : [t], r = n.isArray(r) ? r : [r];
      for (var o = a.prototype.config, u = o._f || (o._f = {}), l = 0, f = t.length; l < f; l++) (o[t[l]] === e || n.isFunction(o[t[l]])) && (o[t[l]] = i);
      for (var c = 0, s = r.length; c < s; c++) u[r[c]] = t[0];
    }
  }, a.prototype.config = {
    name: "lazy",
    chainable: !0,
    autoDestroy: !0,
    bind: "load",
    threshold: 500,
    visibleOnly: !1,
    appendScroll: t,
    scrollDirection: "both",
    imageBase: null,
    defaultImage: "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==",
    placeholder: null,
    delay: -1,
    combined: !1,
    attribute: "data-src",
    srcsetAttribute: "data-srcset",
    sizesAttribute: "data-sizes",
    retinaAttribute: "data-retina",
    loaderAttribute: "data-loader",
    imageBaseAttribute: "data-imagebase",
    removeAttribute: !0,
    handledName: "handled",
    loadedName: "loaded",
    effect: "show",
    effectTime: 0,
    enableThrottle: !0,
    throttle: 250,
    beforeLoad: e,
    afterLoad: e,
    onError: e,
    onFinishedAll: e
  }, n(t).on("load", function () {
    o = !0;
  });
}(window);